import React from "react"
import { graphql } from "gatsby"
import tycStyles from "./tyc.module.scss"
import SEO from "../components/seo"

export default ({ data: { post } }) => (
  <>
  <SEO title='Términos y condiciones'/>
    <div
      className={tycStyles.styles}
      dangerouslySetInnerHTML={{ __html: post.childMarkdownRemark.html }}
    />
  </>
)

export const query = graphql`
  query($path: String) {
    post: googleDocs(path: { eq: $path }) {
      name
      childMarkdownRemark {
        html
      }
    }
  }
`
